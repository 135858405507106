.preview-panel-top__download {
  position: relative;
}

.preview-panel-top__download .far,
.preview-panel-top__download .fas,
.preview-panel-top__download .fab {
  width: 24px;
}

.preview-panel-top__download ul {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  right: 10px;
  border: 1px solid #ccc;
  text-align: left;
  background-color: #fff;
  z-index: 9;
  display: none;
  overflow: auto;
  height: 100px;
  left: 0;
}

.preview-panel-top__download ul li {
  display: block;
  padding: 8px 12px;
  margin: 0;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}
