.font_button {
  background: #fff;
  border: none;
  cursor: pointer;
}
a#linkid {
  display: none;
}
.font_button {
  background: #fff;
  border: none;
}
.header__logo img {
  height: 65px;
  margin: 5px 0 6px;
}
