.TransformComponent-module_container__3NwNd {
  width: 100% !important;
  overflow: auto !important;
}
.TransformComponent-module_content__TZU5O {
  width: 100% !important;
  justify-content: center;
}
#previewImg {
  margin-top: 15px;
}

@media (max-width: 767px) {
  #previewImg {
    margin-top: 0;
    max-width: 100%;
  }
}
