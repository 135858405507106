select.form-control.selected-box-auto-width {
  width: auto;
}
.logo-with-title .place-the-logo img {
  position: relative;
  top: 0;
  transform: translateY(0);
  margin-top: auto;
}

.height-none {
  min-height: unset !important;
  max-height: unset !important;
}
span.add-color-btn {
  background: gray;
  color: white;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}

.item-wrapper-bglabel{
  align-items:normal !important;
}
/* .item-wrapper-bglabel span{
  margin-top: 5px;
} */
.item-wrapper-bglabel .button-square{
  min-width: 140px;
}

.item-wrapper-bglabel .logo-with-title .place-the-logo .choose-the-logo{
  border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 140px;
    margin: 5px 0;
}
