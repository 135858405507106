.displayPopup {
  bottom: 50px;
  opacity: 1;
  z-index: 9;
}

.hidePopup {
  bottom: 0px;
  opacity: 0;
  z-index: -1;
}
.pop-up {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 12px 15px;
  bottom: 0;
  transition: 0.2s;
  opacity: 0;
  z-index: -1;
}
.d-flex {
  display: flex;
}
