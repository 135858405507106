.loader {
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.loader:before {
  background: rgba(128, 128, 128, 0.5);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 9;
}

.loader img {
  position: relative;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
}
