.ControlPanel {
  background-color: #f8f9fa;
}
.custom-h-tabs .react-tabs ul#tabs li a {
  display: inline-block !important;
  padding: 15px 2px !important;
  color: #a1a2a3 !important;
  text-transform: uppercase !important;
  position: relative !important;
  font-weight: 600 !important;
}

.react-tabs__tab--selected {
  color: #3193ef !important;
  background: none !important;
}
