#place-the-bg {
  max-height: 200px;
  min-height: 200px;
  overflow-y: auto;
}

.logo-with-title .place-the-logo img {
  position: relative;
  top: 0;
  transform: translateY(0);
  margin-top: auto;
}
