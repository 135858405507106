.displayPopup {
  bottom: 50px;
  opacity: 1;
  z-index: 9;
}

.hidePopup {
  bottom: 0px;
  opacity: 0;
  z-index: -1;
}
#font_button {
  background: #fff;
  border: none;
  position: relative;
}
.font_button {
  background: #fff;
  border: none;
}
.add-cart-btn {
  padding: 6px 10px;
  font-size: 11px;
  min-width: auto;
  font-weight: 700;
}

.tooltip-text {
  position: absolute;
  left: -85px;
  background: #000;
  color: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  width: 200px;
  top: -52px;
  display: none;
}

.tooltip-text:before {
  content: "";
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#display-alertBoxModel .modal-content{
  border-radius: 10px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#display-alertBoxModel .place-the-logo-type{
  display: flex;
  align-items: center;
  font-size: 16px;
}

#display-alertBoxModel #place-the-bg {
  max-height: 100px;
  min-height: 100px;
  overflow-y: auto;
}
#display-alertBoxModel.modal{
  padding-top: 0;
}
#display-alertBoxModel .disp-msg{
  font-size: 16px;
}