.text-danger {
  font-size: 16px;
}
#fileTypeModel .modal-content {
  border-radius: 5px;
}
.delete-logo-wrapper .button.delete-btn {
  margin-right: 8px !important;
  margin-top: 5px !important;
}

.delete-btn {
  margin-top: 5px;
  margin-right: 8px;
}
.text-red {
  color: red;
}

.company__input-wrapper .inp-wrapper {
  display: flex;
  align-items: center;
}

.company__input-wrapper .inp-wrapper .Input_Input__kUNWy {
  width: 100%;
}

.company__input-wrapper .font-icon-input .font_button {
  background: none;
  font-size: 20px;
  line-height: 1;
}

.company__input-wrapper .displayPopup .select-font-alignment .font_button {
  margin-right: 5px;
}
.inp-wrapper > div:first-child {
  width: 100%;
}
.bullet-str-btn{
  min-height: 25px;
    min-width: 25px;
}

@media(min-width:767px){
  .stub_single_span{
    width: 50%;
  }
  .stub_1_wrapper{
    display: flex;
    align-items: center;
  }
  .stub_1_wrapper{
    position: relative;
  }
  .stub_1_wrapper .inp-wrapper{
    position: inherit;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .stub_1_wrapper{
    text-align: center;
  } 
}