.choose-the-logo-type {
  width: 100px;
  margin-right: 10px;
}

.choose-the-logo-type img {
  max-width: 100%;
  height: 100%;
  text-align: center;
}
#side-logo-img {
  height: auto !important;
}

#place-logo-modal .modal-content .modal-body {
  padding-left: 0;
  padding-right: 0;
}

#place-logo-modal .radio-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.text-center{
text-align:center;
padding: 0px 15px 0px 10px;
}

#place-logo-modal .grid-item [type="radio"]:checked,
#place-logo-modal .grid-item [type="radio"]:not(:checked) {
  position: absolute;
}

#place-logo-modal .grid-item > span {
  display: inline-block;
  margin-bottom: 5px;
}

#place-logo-modal .grid-item > div {
  display: flex;
  margin-left: 26px;
}

#place-logo-modal .grid-item.logo-place-top > div {
  display: block;
}

#place-logo-modal .grid-item.logo-place-top .choose-the-logo-type {
  width: 100%;
  height: 74px;
  overflow: hidden;
  margin-bottom: 10px;
  text-align: center;
}

#place-logo-modal .grid-item.logo-place-top .choose-the-logo-type img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#place-logo-modal .grid-item.logo-place-top .company-information {
  text-align: center;
}

.classNotImgae {
  background: #3193ef;
  text-align: center;
  border: 1px solid #6d7173;
}

.classNotImgae > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  padding: 0 10px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 70%;
}
.logo-place-top .text-ellipsis {
  margin: 0 auto;
}
@media (min-width: 767px) {
  #place-logo-modal .modal-content {
    width: 650px;
  }
}

@media (max-width: 767px) {
  #place-logo-modal .grid-item {
    margin-bottom: 20px;
  }
}
