.logo-with-title .place-the-logo img {
  position: relative;
  top: 0;
  transform: translateY(0);
  margin-top: auto;
}

.popup-choose-a-logo .logo-with-title .place-the-logo img {
  margin-top: 0;
  object-fit: contain;
  height: 100%;
}

.place-the-logo {
  max-height: 135px;
  min-height: 135px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.popup-choose-a-logo .place-the-logo {
  max-height: 160px;
  min-height: 160px;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}

.logo-with-title .place-the-logo .choose-the-logo {
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-choose-a-logo .logo-with-title .place-the-logo .choose-the-logo {
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  height: 130px;
}

.logo-with-title .place-the-logo .choose-the-logo .logo-image-name {
  margin-top: auto;
}

.popup-choose-a-logo
  .logo-with-title
  .place-the-logo
  .choose-the-logo
  .logo-image-name {
  margin-top: auto;
  white-space: nowrap;
  width: 130px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  padding: 0 10px;
}

.logo-popup-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-popup-bottom__left {
  position: relative;
}

.logo-popup-bottom__left input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 40px;
}

.logo-popup-bottom__left--upload i {
  font-size: 38px;
  float: left;
  margin-right: 6px;
}

.logo-popup-bottom__left--upload > div {
  overflow: hidden;
}

.logo-popup-bottom__left--upload > div span {
  font-size: 14px;
  display: block;
  text-transform: capitalize;
}

.logo-popup-bottom__left--upload > div small {
  display: block;
  font-size: 10px;
}
.logo-popup-bottom__left--upload{
  display: flex;
  align-items: center;
}

.place-logo-imgsec {
  width: 100%;
  height: 95px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#myModalLogo {
  padding-top: 15px;
}

#myModalLogo .logo-position h4 {
  margin-bottom: 2px;
}

#myModalLogo .logo-position {
  padding-bottom: 5px;
  margin-bottom: 5px;
}

#myModalLogo.popup-choose-a-logo .place-the-logo {
  max-height: 140px;
  min-height: 140px;
}

#myModalLogo .modal-content .modal-header,
.modal-content .modal-body {
  padding: 10px;
}
#myModalLogo .place-the-logo-with-error {
  min-height: 50px !important;
  max-height: 50px !important;
}

/* @media (min-width: 460px) and (max-width: 900px) {
  #myModalLogo.popup-choose-a-logo .place-the-logo {
    max-height: 70px;
    min-height: 70px;
  }

  #myModalLogo .place-logo-imgsec {
    height: 50px;
  }
} */

@media (max-width: 460px) {
  .place-the-logo .choose-the-logo {
    width: 21%;
  }
  #myModalLogo.popup-choose-a-logo .place-the-logo {
    max-height: 120px;
    min-height: 120px;
  }
}
.upload-logo{
  padding-right: 8px;
  width: 50px;
}

input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
  }
  .upload-button-right:hover{
    cursor: pointer;
  }
  